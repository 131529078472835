
import { dispatchGetProductsByKindList } from '@/store/products/actions';
import { Component, Vue } from 'vue-property-decorator';
import ProductGridCard from '@/components/Products/ProductGridCard.vue';
import ProductListCard from '@/components/Products/ProductListCard.vue';
import { IProductsListResponse } from '@/interfaces/products';

@Component({
  components: {
    ProductGridCard,
    ProductListCard,
  },
})

export default class ProductsKindsList extends Vue {
  public kindProductsList: IProductsListResponse = {
    count: 0,
    next: 0,
    current: 0,
    previous: 0,
    total: 0,
    total_count: 0,
    results: [],
  };
  public perPage = 10;
  public page = 1;
  public loadingState: any = {
    page: true,
  };
  public productListType = 'grid';
  public productSortType = '-rating';

  public async mounted() {
    this.handleChangePage(1, true);
    this.loadingState.page = false;
  }

  public async handleChangePage(page, force = false) {
    if (this.page != page || force) {
      this.page = page;
      const rowQuery = `?page=${this.page}&per_page=${this.perPage}`;
      this.kindProductsList = await dispatchGetProductsByKindList(
        this.$store, {
          data: {
            creature_kind: Number(this.$route.params.id),
            brand: this.$route.query.brand ? this.$route.query.brand.toString() : null,
            order_by: this.productSortType,
          }, rowQuery,
        },
      );
    }
  }

  public hadnleChangeOrdering(ordering) {
    if (this.productSortType !== ordering) {
      this.productSortType = ordering;
      this.handleChangePage(1, true);
    }
  }

  get sortTitle() {
    if (this.productSortType === '-rating') {
      return this.$t('По рейтингу');
    } else if (this.productSortType === '-created_at') {
      return this.$t('По дате');
    } else if (this.productSortType === 'price') {
      return this.$t('От дешевых к дорогим');
    } else if (this.productSortType === '-price') {
      return this.$t('От дорогих к дешевым');
    }
    return '';
  }
}
